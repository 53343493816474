<template>
    <div id="app">
        <transition name="slide"><router-view /></transition>
    </div>
</template>
<script language="Javascript"> 
</script> 
<script>
    export default {
        name: "APP",
		created() {
		      this.$nextTick(() => {
		        // 禁用右键
		        document.oncontextmenu = new Function("event.returnValue=false");
		        // 禁用选择
		        document.onselectstart = new Function("event.returnValue=false");
		      });
		}
    }
</script>
<style lang="scss" scoped>
    /*每个页面公共css */
    @import url("assets/styles/iconfont.css");
    @import "assets/styles/main.scss";
</style>
