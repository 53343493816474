import http from "../../utils/http"

/**
 * 获取资讯列表
 */
export function articleList(params) {
    return http({
        url: "/api/articleclass/lists",
        data: params
    })
}
/**
 * 获取文章列表
 */
export function cms_lists(params) {
    return http({
        url: "/api/articleclass/cms_lists",
        data: params
    })
}
/**
 * 获取资讯详情
 */
export function articleDetail(params) {
    return http({
        url: "/api/article/info",
        data: params
    })
}
/**
 * 获取资讯详情
 */
export function articleOther(params) {
    return http({
        url: "/api/article/page",
        data: params
    })
}
