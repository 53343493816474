<template oncontextmenu="return false" onselectstart="return false">
	<div class="footer-bottom">
		<!-- <div class="site-info">
			<p v-if="siteInfo.web_phone"><i class="iconfont icondianhua"></i>{{ siteInfo.web_phone }}</p>
			<p v-if="siteInfo.web_email">
				<i class="iconfont iconyouxiang"></i>
				<el-link :href="`mailto:${siteInfo.web_email}`">{{ siteInfo.web_email }}</el-link>
			</p>
		</div> -->
		<p v-for="(item, index) in helpList" :key="index" style="text-align: center;">
			<a class="footer-link" v-for="(helpItem, helpIndex) in item.child_list" :key="helpIndex"
				@click="clickToHelpDetail(helpItem)">&nbsp;&nbsp;{{ helpItem.title }}　<span v-if="helpIndex!=item.child_list.length-1">&nbsp;|&nbsp;</span>　</a>
		</p>
		<p>
			{{ copyRight.copyright_desc }}
			<a class="footer-link" href="#" target="_blank">京灯（广东）信息科技有限公司版权所有</a>&nbsp;
			<a v-if="copyRight.icp" class="footer-link" href="https://beian.miit.gov.cn" target="_blank">
				备案号：{{ copyRight.icp }}</a> &nbsp;
			<!-- <a v-if="copyRight.gov_record" class="footer-link" :href="copyRight.gov_url" target="_blank">
			    <img src="@/assets/images/gov_record.png" alt="公安备案" />&nbsp;
			    <span>{{ copyRight.gov_record }}</span>
			</a> -->
		</p>
		<p>
			<a v-if="copyRight.gov_record" class="footer-link" :href="copyRight.gov_url" target="_blank">
				<img src="@/assets/images/gov_record.png" alt="公安备案" />
				<span>{{ copyRight.gov_record }}</span>
			</a>
		</p>
	</div>
</template> 
<script>
	import {
		copyRight
	} from "@/api/website"
	import {
		mapGetters
	} from "vuex"
	import {
		cms_lists
	} from "@/api/cms/article"
	export default {
		props: {},
		data() {
			return {
				helpList: [],
			}
		},
		computed: {},
		created() {
			this.$store.dispatch("site/copyRight")
			this.getHelpList()
			// this.setDisable()
		},
		mounted() {},
		watch: {},
		methods: {
			/* setDisable() {
				$(document).ready(function() {
					document.oncontextmenu = function() {
						return false;
					}
					document.onselectstart = function() {
						return false;
					}
				});
			}, */
			/**
			 * 获取帮助列表
			 */
			getHelpList() {
				cms_lists()
					.then(res => {
						if (res.code == 0 && res.data) {
							var arr = [];
							arr = res.data.slice(0, 8)

							for (let i = 0; i < arr.length; i++) {
								arr[i].child_list = arr[i].child_list.slice(0, 8);
							}

							this.helpList = arr
						}
					})
					.catch(err => {})
			},
			clickToHelpDetail(e) {
				console.log(e);
				if(e.link_address==null||e.link_address==""){
					if(e.title=='关于我们'){
						this.$router.push("/cms/about-" + e.id)
					}else{
						this.$router.push("/cms/article-" + e.id)
					}
				}else{
					window.open(e.link_address,'_blank');
				}
			},
		},
		computed: {
			...mapGetters(["copyRight", "siteInfo"])
		}
	}
</script>

<style scoped lang="scss">
	.footer-bottom {
		width: 100%;
		height: 70px;
		margin: 0 auto;
		padding: 20px 0;

		p {
			margin: 0;
			width: 100%;
			box-sizing: border-box;
			text-align: center;

			img {
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}

			.footer-link,
			.el-link.el-link--default {
				color: #9d9d9d;
			}

			.footer-link:hover,
			.el-link.el-link--default:hover {
				color: $base-color;
			}
		}

		.site-info {
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				width: auto;
				margin: 0 10px;

				i {
					vertical-align: bottom;
					margin-right: 5px;
				}
			}
		}
	}
</style>
