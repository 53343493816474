<template>
	<div class="footer">
		<el-tabs v-model="activeName" class="friendly-link" v-if="linkList.length > 0">
			<el-tab-pane label="友情链接" name="first">
				<div>
					<div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index"
						:title="link_item.link_title">
						<span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img
								:src="$img(link_item.link_pic)" /></span>
					</div>

					<div class="clear"></div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<!-- <div class="friendly-link" v-if="linkList.length > 0">
			<div class="link-title">友情链接</div>
			<div>
				<div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">
					<span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)" /></span>
				</div>

				<div class="clear"></div>
			</div>
		</div> -->
		<div class="footer-top" v-if="shopServiceList.length > 0">
			<ul class="service">
				<li v-for="(item, index) in shopServiceList" :key="index">
					<!-- <div><i class="iconfont" :class="item.icon"></i></div> -->
					<div><img class="iconfont" style="padding-bottom: 25px !important;" :src="item.icon"></img></div>
					<p>{{ item.name }}</p>
				</li>
			</ul>
		</div>

		<div class="footer-center" style="padding-left: 11%;">
			<!-- <div class="left" v-if="siteInfo.web_phone || siteInfo.web_email">
                <div>
                    <p class="left-phone" v-if="siteInfo.web_phone">{{ siteInfo.web_phone }}</p>
                    <p class="left-email">{{ siteInfo.web_email }}</p>
                </div>
            </div> -->
			<div class="center" v-if="helpList.length">
				<div class="help" v-for="(item, index) in helpList" :key="index">
					<p @click="clickToHelp(item.class_id)">{{ item.class_name }}</p>
					<ul class="help-ul">
						<li class="help-li" v-for="(helpItem, helpIndex) in item.child_list" :key="helpIndex"
							@click="clickToHelpDetail(helpItem)">{{ helpItem.title }}</li>
					</ul>
				</div>
			</div>
			<div class="right">
				<div>
					<el-image v-if="siteInfo.web_qrcode" :src="$img(siteInfo.web_qrcode)" @error="imageError">
						<div slot="error" class="image-slot">
							<img :src="require('@/assets/images/wxewm.png')" />
						</div>
					</el-image>
					<el-image v-else :src="require('@/assets/images/wxewm.png')"></el-image>
					<!-- <p>微信小程序</p> -->
					<p style="height: 30px;">
						<a href="https://weibo.com/2800522890" target="_blank" style="float: left;">
							<img src="../../assets/images/新浪微博.png" class="jingdeng_img" />
						</a>
						<img src="../../assets/images/微信.png" @mouseover="orerwx()" @mouseleave="leavewx()" class="jingdeng_img" />　　　　
						<a style="float: right;">
							<img src="../../assets/images/抖音.png" @mouseover="orerdy()" @mouseleave="leavedy()" class="jingdeng_img" />
						</a>
					</p>
				</div>
				
				<div v-if="wx_flag" class="wx_flag">
					<img src="../../assets/images/footerwx.png" style="width: 180px;height: 240px;"/>
					<!-- <div>
						<img src="../../assets/images/小程序.jpg" />
						<div class="div_info">
							<span>微信扫一扫</span>
							<span style="color: darkorange;">使用小程序</span>
						</div>
					</div>
					<div>
						<img src="../../assets/images/京灯视频号.jpg" />
						<div class="div_info">
							<span>微信扫一扫</span>
							<span style="color: darkorange;">关注视频号</span>
						</div>
					</div>
					<div>
						<img src="../../assets/images/京灯党支部二维码.jpg" />
						<div class="div_info">
							<span>关注行业</span>
							<span style="color: darkorange;">获取政企最新动态</span>
						</div>
					</div> -->
				</div>
				<div v-if="dy_flag" class="dy_flag">
					<div>
						<img src="../../assets/images/京灯抖音.jpg" />
						<!-- <div class="div_info" style="margin-left: 10px;">
							<span>抖音扫一扫</span>
							<span style="color: darkorange;">关注抖音号</span>
						</div> -->
					</div>
				</div>
			</div>
		</div>

		<div class="footer-bot">
			<copy-right />
		</div>
	</div>
</template>

<script>
	import {
		copyRight,
		shopServiceLists,
		friendlyLink
	} from "@/api/website"
	import CopyRight from "./CopyRight"
	import {
		mapGetters
	} from "vuex"
	import {
		helpList
	} from "@/api/cms/help"

	export default {
		props: {},
		data() {
			return {
				shopServiceList: [],
				linkList: [],
				helpList: [],
				ishide: false,
				activeName: "first",
				wx_flag: false,
				dy_flag: false,
			}
		},
		computed: {
			...mapGetters(["siteInfo"])
		},
		created() {
			this.getShopServiceLists()
			this.link()
			this.getHelpList()
		},
		mounted() {},
		watch: {},
		methods: {
			orerwx() {
				this.wx_flag = true
			},
			leavewx() {
				this.wx_flag = false
			},
			orerdy() {
				this.dy_flag = true
			},
			leavedy() {
				this.dy_flag = false
			},
			getShopServiceLists() {
				shopServiceLists({}).then(res => {
					if (res.code == 0 && res.data) {
						this.shopServiceList = res.data
					}
				})
			},
			link() {
				friendlyLink({})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.linkList = res.data.list
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			},
			linkUrl(url, target) {
				if (!url) return
				if (url.indexOf("http") == -1) {
					if (target) this.$router.pushToTab({
						path: url
					})
					else this.$router.push({
						path: url
					})
				} else {
					if (target) window.open(url)
					else window.location.href = url
				}
			},
			/**
			 * 获取帮助列表
			 */
			getHelpList() {
				helpList()
					.then(res => {
						if (res.code == 0 && res.data) {
							var arr = [];
							arr = res.data.slice(0, 5)

							for (let i = 0; i < arr.length; i++) {
								arr[i].child_list = arr[i].child_list.slice(0, 4);
							}

							this.helpList = arr
						}
					})
					.catch(err => {})
			},
			/**
			 * 图片加载失败
			 */
			imageError() {
				this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
			},
			/**
			 * 跳转到帮助列表
			 */
			clickToHelp(id) {
				this.$router.push("/cms/help/listother-" + id)
			},
			/**
			 * 跳转到帮助详情
			 */
			clickToHelpDetail(e) {
				if(e.link_address==null||e.link_address==""){
					this.$router.push("/cms/help-" + e.id)
				}else{
					window.open(e.link_address,'_blank');
				}
			},
		},
		components: {
			CopyRight
		}
	}
</script>

<style scoped lang="scss">
	.wx_flag {
		height: 200px;
		width: 200px;
		margin-left: 65px;
		text-align: left;
		margin-top: -30px;

		div {
			height: 80px !important;
			display: flex;
		
			img {
				width: 80px !important;
			}
		}
	}

	.dy_flag {
		height: 200px;
		width: 240px;
		margin-left: 65px;
		text-align: left;
		margin-top: -30px;
		
		div {
			height: 150px !important;
			display: flex;
			img {
				width: 150px !important;
			}
		}
	}
	.div_info{
		display: flex;
		flex-direction: column;
		
		span {
			font-size: 12px;
		}
	}

	.jingdeng_img {
		width: 20px !important;
		height: 20px !important;
	}

	.footer {
		.footer-top {
			background-color: #fff;

			.service {
				margin: 0;
				padding: 0;
				width: $width;
				margin: 0 auto;
				padding: 25px 0;
				box-sizing: border-box;
				border-bottom: 1px solid #e9e9e9;
				display: flex;
				justify-content: space-around;
				align-items: center;

				li {
					list-style: none;
					line-height: 30px;
					text-align: center;
					flex: 1;

					div {
						display: inline-block;
						width: 48px;
						height: 48px;
						line-height: 48px;

						i {
							font-size: 48px;
							color: $base-color;
						}
					}

					p {
						font-size: 16px;
						line-height: 20px;
						margin-top: 10px;
					}
				}
			}
		}

		.footer-center {
			width: $width;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding: 40px 0;

			.left {
				width: 300px;

				.left-phone {
					font-size: 26px;
					font-weight: 600;
					color: $base-color;
					padding-bottom: 10px;
					border-bottom: 1px solid $base-color;
					margin-bottom: 12px;
				}

				.left-email {
					font-size: 18px;
					color: #838383;
				}
			}

			.center {
				width: calc(100% - 450px);
				// padding: 0 50px;
				display: flex;
				justify-content: space-between;

				p {
					font-size: 18px;
					font-weight: 600;
					color: #838383;
					margin-bottom: 10px;
					cursor: pointer;
				}

				.help-li {
					// padding-left: 20;
					font-size: 14px;
					color: #838383;
					line-height: 30px;
					cursor: pointer;
				}

				p:hover,
				.help-li:hover {
					color: $base-color;
				}
			}

			.right {
				width: 355px;
				display: flex;
				text-align: center;

				.el-image {
					width: 120px;
					height: 120px;
					line-height: 120px;
					text-align: center;

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				p {
					font-size: 18px;
					color: #838383;
				}
			}

			.qrcode-hide {
				display: none;
			}
		}

		.footer-bot {
			background: #242424;
			color: #9D9D9D;
		}

		.friendly-link {
			width: $width;
			margin: 0 auto;
			border: 1px solid #e9e9e9;

			.link-title {
				line-height: 30px;
				padding: 10px 0 5px;
				margin: 0px 0 15px;
				border-bottom: 1px solid #e8e8e8;
			}

			.link-item {
				width: 10%;
				height: 50px;
				line-height: 47px;
				float: left;
				text-align: center;
				overflow: hidden;
				margin: 0 6px 10px 6px;
				-webkit-transition: opacity 0.3s, box-shadow 0.3s;
				transition: opacity 0.3s, box-shadow 0.3s;
				border: 1px solid #fff;
				border-left: none;
				cursor: pointer;
			}

			.link-item:hover {
				width: -webkit-calc(10% + 1px);
				width: calc(10% + 1px);
				margin-left: 4px;
				position: relative;
				opacity: 1;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				border: 1px solid #dddddd;
			}

			.clear {
				clear: both;
			}
		}
	}
</style>
<style lang="scss">
	.friendly-link {
		.el-tabs__nav-scroll {
			padding-left: 30px;
			height: 50px;
			line-height: 50px;
		}

		.el-tabs__content {
			padding: 0 20px;
		}

		.el-tabs__nav-wrap::after {
			height: 1px;
		}

		.el-tabs__nav {
			padding: 0 20px;
		}

		.el-tabs__active-bar {
			padding: 0 20px;
		}
	}
</style>
